exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-1-strona-w-budowie-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/1-strona-w-budowie/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-1-strona-w-budowie-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-2-rozwoj-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/2-rozwoj/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-2-rozwoj-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-3-za-chwile-rusza-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/3-za-chwile-rusza/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-3-za-chwile-rusza-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konkurs-2-js": () => import("./../../../src/pages/konkurs2.js" /* webpackChunkName: "component---src-pages-konkurs-2-js" */),
  "component---src-pages-konkurs-js": () => import("./../../../src/pages/konkurs.js" /* webpackChunkName: "component---src-pages-konkurs-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */)
}

